import logo from './assets/images/logo3.svg';

import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import DropdownMenu from './components/Dropdown';

import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from "react-router-dom"
import usePageTracking from './hooks/usePageTracking';


/**
 * React component defining the header and routes.
 * @returns {JSX} - Root component
 */
export default function App() {

  usePageTracking();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="app">
      <header className="header-bar flex flex-row justify-between items-center py-1">
          <div className='flex flex-row items-center pt-2'>
            <a href="https://www.lumos-health.com"><img src={logo} className="header-img-logo p-2 ml-4 mt-1 mb-1" alt="lumos logo"/></a>
          </div>
          <div className='header-right-items'>
              <a className="lumos-blue header-options py-2" href="https://www.lumos-health.com/about">About</a>
              <a href="https://www.blog.lumos-health.com" className="lumos-blue header-options py-2" target="_blank" rel="noreferrer">Blog</a>
              <button className="header-get-started-btn">
                <a href="https://www.marketplaceapp.lumos-health.com" className="text-white header-get-started" target="_blank">Get started</a>
              </button>
          </div>
          <div className='header-menu-button'>
          <DropdownMenu isOpen={isDropdownOpen} />
            <div className={`menu-toggle ${isDropdownOpen ? 'active' : ''}`}
              onClick={() => setDropdownOpen(!isDropdownOpen)}>
              &#9776;
            </div>
            </div>
      </header>
      <main onClick={() => setDropdownOpen(false)}>
          <Routes>
              <Route path="/" element={<BlogList />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
          </Routes>
      </main>
    </div>
  );
}
