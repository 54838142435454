import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import blogsData from '../data/blogsData'; // Load metadata
import DOMPurify from 'dompurify';

function BlogPost() {
  const { slug } = useParams();
  const blogMetadata = blogsData.find((blog) => blog.slug === slug);

  // Initialize content to an empty string
  const [SanitizedContent, setSanitizedContent] = useState('');

  useEffect(() => {
    // Check if the blogMetadata exists
    if (blogMetadata) {
      // Load the blog content from the specified HTML file
      fetch(`/blogs/${blogMetadata.contentPath}`)
        .then((response) => response.text())
        .then((htmlContent) => {
          setSanitizedContent(DOMPurify.sanitize(htmlContent));
        });
    }
  }, [blogMetadata]);

  // Check if blogMetadata is undefined and display an error message
  if (!blogMetadata) {
    return <div className="blog-post">Blog not found.</div>;
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: SanitizedContent }} />
    </div>
  );
}

export default BlogPost;